<template>
  <v-card flat width="400">
    <v-card-title>
      Welcome
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field label="Email" name="login" type="text" v-model="email" outlined dense placeholder=" " autofocus
          :error-messages="form_error.email" :class="{ 'mb-3': form_error.email }"></v-text-field>

        <v-text-field id="password" label="Password" name="password" type="password" v-model="password"
          :error-messages="form_error.password" @keyup.enter.native="login()" outlined dense placeholder=" "
          :class="{ 'mb-3': form_error.password }"></v-text-field>
        <v-btn block color="primary" @click="login()" :loading="loading" :disabled="loading">Login
        </v-btn>
        <div class="mt-3 mb-0  text-center ">
          Don't have an account ?
          <a @click="$router.push(`/register`)">
            Register
          </a>
        </div>
        <div class="mt-3 mb-0  text-center ">
          <a @click="$router.push(`/forgot-password`)">
            Forgot Password?
          </a>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "@/helper/axios";

export default {
  name: "Login",
  data() {
    return {
      form_error: {},
      email: null,
      password: null,
      loading: false,
    };
  },
  methods: {
    login() {
      let _self = this;
      this.loading = true;
      axios
        .post("/user/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            this.data = response_data.user_data;
            localStorage.setItem(
              "pcm_user",
              JSON.stringify(response_data.user_data)
            );
            axios.defaults.headers.common = {
              Authorization: "Bearer " + response_data.user_data.attendee_token,
              Accept: "application/json",
            };
            axios.defaults.baseURL = process.env.VUE_APP_API_URL + "/user/";
            _self.$router.push("/app/");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
};
</script>
